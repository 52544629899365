import React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';

const SuccessPage = () => (
  <Card>
    <CardHeader title="Success login" />
    <CardContent>Russian warship go fuck yourself! </CardContent>
  </Card>
);

export default SuccessPage;

/* eslint-disable react/prop-types */
import React from 'react';

const getPureValue = (val) => {
  const isEvent = !!val?.target;
  const isCheckbox = isEvent && val.target.type === 'checkbox';
  switch (true) {
    case isCheckbox: return val.target.checked;
    case isEvent: return val.target.value;
    default: return val;
  }
};

export const FormikMaterialWrapper = ({ ComponentToWrap, formik, name, ...props }) => {
  if (!ComponentToWrap) {
    throw new Error('No component to wrap has been provided');
  }
  const { handleBlur, values, touched, errors } = formik || {};
  const changeHandler = (val) => {
    const pureValue = getPureValue(val);
    formik.setFieldValue(name, pureValue);
    formik.setFieldTouched(name, true);
  };

  if (!formik) { return <ComponentToWrap {...props} />; }

  return (
    <ComponentToWrap
      onChange={changeHandler}
      onBlur={handleBlur}
      value={values[name]}
      error={touched[name] && Boolean(errors[name])}
      helperText={touched[name] && errors[name]}
      {...props}
    />
  );
};

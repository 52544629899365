/* eslint-disable i18next/no-literal-string */
import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { loginUser } from 'utils/api';
import redirect from 'utils/redirect';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { FormikMaterialWrapper } from 'utils/FormikMaterialWrapper';

const theme = createTheme();

const validationSchema = yup.object({
  email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});

const Authorization = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [userToken, setUserToken] = useState(localStorage.getItem('token'));
  const [showPass, setShowPass] = useState(false);
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: false,
    },
    validationSchema,
  });

  const fields = [
    {
      id: 'email',
      name: 'email',
      label: 'Email',

    },
    {
      id: 'password',
      name: 'password',
      label: 'Password',
      type: showPass ? 'text' : 'password',
      InputProps: {
        endAdornment: (
          <IconButton onClick={() => setShowPass(!showPass)}>
            {showPass && <Visibility />}
            {!showPass && <VisibilityOff />}
          </IconButton>
        ),
      },
    },
  ];

  useEffect(() => {
    if (!userToken) {
      return;
    }
    const callbackURL = query.get('callback_url');
    if (!callbackURL) {
      history.push('/success');
      return;
    }
    const URL = `${callbackURL}?token=${userToken}`;
    redirect(URL);
  }, [userToken]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const tokenRequest = async () => {
      const token = await loginUser(formik.values.email, formik.values.password);
      if (!token) {
        toast.error('Your authorization failed');
        return;
      }

      if (formik.values.remember) {
        localStorage.setItem('token', token);
      }
      setUserToken(token);
    };
    tokenRequest().then();
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            {fields.map((field) => (
              <FormikMaterialWrapper
                {...field}
                formik={formik}
                fullWidth
                margin="normal"
                ComponentToWrap={TextField}
              />
            ))}
            <FormControlLabel
              label="Remember me"
              control={(
                <FormikMaterialWrapper
                  ComponentToWrap={Checkbox}
                  name="remember"
                  formik={formik}
                  color="primary"
                />
              )}
            />
            <Button type="submit" fullWidth variant="contained" color="info" sx={{ mt: 3, mb: 2 }}>
              Sign in
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Authorization;

import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Authorization from 'components/common/Authorization/Authorization';
import SuccessPage from 'components/common/Component/SuccessPage';
import LogOutPage from 'components/common/Component/LogOutPage';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/login" exact component={Authorization} />
      <Route path="/success" exact component={SuccessPage} />
      <Route path="/logout" exact component={LogOutPage} />
      <Route path="*" render={() => <Redirect to="/login" />} />
    </Switch>
    <ToastContainer
      position="top-right"
      autoClose={3000}
    />
  </BrowserRouter>
);

export default Routes;

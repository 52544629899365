import { useHistory, useLocation } from 'react-router-dom';

const LogOutPage = () => {
  localStorage.removeItem('token');
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const callbackURL = query.get('callback_url');
  if (callbackURL) {
    history.push(`/login?callback_url=${encodeURIComponent(callbackURL)}`);
  } else {
    history.push('/login');
  }
  return null;
};

export default LogOutPage;

/* eslint-disable i18next/no-literal-string */
import axios from 'axios';
import { toast } from 'react-toastify';

export const isReadOnly = () => {
  const defaultReadOnly = process.env.REACT_APP_READ_ONLY || 'false';
  const { readOnly = defaultReadOnly } = window.config || {};
  return readOnly === 'true';
};

export const getApiUrl = () => {
  const defaultApiUrl = process.env.REACT_APP_API_URL || '';
  const { apiUrl = defaultApiUrl } = window.config || {};
  return apiUrl;
};

const axiosInstance = axios.create({
  baseURL: getApiUrl(),
  timeout: 30000,
});

const assertError = (data) => {
  const code = data?.code || -1;
  if (code !== 200) {
    const message = data?.message || `Unknown error with code: ${code}`;

    toast.error(message);
    throw new Error(message);
  }
};

export const getEquipmentHierarchy = async () => {
  const { data } = await axiosInstance.get(
    '/api/equipment-management/equipment/connector/equipment/hierarchy',
  );
  assertError(data);
  return data;
};

export const getProjectList = async () => {
  try {
    const { data } = await axiosInstance.post(
      '/api/iot-data-service/project/getProjectList',
      {},
    );
    assertError(data);
    return data;
  } catch (e) {
    toast.error(e.message);
    return [];
  }
};

export const getProjectById = async (id) => {
  const { data } = await axiosInstance.get(
    `/api/iot-data-service/project/getProjectById/${id}`,
  );
  assertError(data);
  return data;
};

export const updateProject = async (updatedData) => {
  if (isReadOnly()) {
    return null;
  }
  const { data } = await axiosInstance.put(
    '/api/iot-data-service/project/updateProject',
    updatedData,
    {
      headers: { 'content-type': 'application/json' },
    },
  );
  assertError(data);
  return data;
};

export const getEquipmentSetting = async (id) => {
  const { data } = await axiosInstance.get(
    `/api/equipment-management/setting/equipment/${id}`,
  );
  assertError(data);
  return data;
};

export const getEquipmentFieldsData = async (equipmentId, fieldsIds) => {
  const { data } = await axiosInstance.get(
    `/api/equipment-management/equipment/${equipmentId}/data/${fieldsIds.join(',')}`,
  );
  assertError(data);
  return data;
};

export const getConnectionValues = async (params) => {
  const { data } = await axiosInstance.post('/realtime', params);
  return data;
};

export const sqlEvalMysql = async (params) => {
  const { data } = await axiosInstance.post('/mysql', params);
  return data;
};

export const sqlEvalInflux = async (params, version = 2) => {
  const { data } = await axiosInstance.post(`/influx/v${version}`, params);
  return data;
};

export const sqlParseQuery = async (sqlQuery) => {
  const { data } = await axiosInstance.post('/parse', { sqlQuery });
  return data;
};

export const loginUser = async (email, password) => {
  try {
    const { data } = await axiosInstance.post('/api/auth/login', { email, password });
    return data.token;
  } catch (err) {
    console.error(err);
    return null;
  }
};
